<template>
  <div
    class="card mb-4"
    v-if="
      this.$store.state.getMovie.status.similarTvLoading && similarTv.length
    "
  >
    <div class="card-header bg-success">
      <h4 class="text-center text-white my-2">سریال های مشابه {{ tv.name }}</h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div
          class="col-sm-6 col-md-4 col-lg-3"
          v-for="movie in similarTv"
          :key="movie.id"
        >
          <cardViewTv
            :movie="movie"
            :size="imageSizeMovie('w342')"
          ></cardViewTv>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cardViewTv from "@/components/cardView/cardViewTv";
export default {
  name: "SimilarTv",
  components: { cardViewTv },
  props: {
    tv: {
      type: Object,
      required: true,
    },
    similarTv: {
      type: Object,
    },
  },
};
</script>

<style scoped></style>
